import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../Components/Menu";
import BottomNavigationButton from "../Components/BottomNavigationButton";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useMyContext } from "../context";
import * as mutations from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { v4 as uuidv4 } from "uuid";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

const client = generateClient();

function Dashboard() {
  const { setIsLoading, user, allowUpload, AIModel, fetchHistory } =
    useMyContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("Morning");
    } else if (currentHour < 18) {
      setGreeting("Afternoon");
    } else {
      setGreeting("Evening");
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!user?.testProfileConfig) {
        navigate("/task");
      }
    }
  }, [user]);

  // const handleFileChange = async (e) => {
  //   const selectedFile = e.target.files[0];
  //   if (selectedFile) {
  //     uploadFile(selectedFile);
  //   }
  // };

  const uploadFile = async (selectedFile) => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("taste_conf", user.testProfileConfig);
        formData.append("AIModel", AIModel);
        formData.append(
          "attitude",
          user.attitude === "Casual"
            ? "somebody with a fun and cheeky attitude"
            : user.attitude
        );

        let response = await axios.post(
          "https://api.heysomm.ai/api/chat",
          formData
        );
        response = response.data;

        uploadData({
          key: `${uuidv4()}-${selectedFile.name}`,
          data: selectedFile,
        }).result.then(async (result) => {
          await client.graphql({
            query: mutations.createUerWineHistory,
            variables: {
              input: {
                id: uuidv4(),
                userId: user.userId,
                wineImg: result.key,
                response: JSON.stringify(response),
                favorite: false,
              },
            },
          });
          fetchHistory(false);
        });

        navigate("/resultScreen", { state: { result: response } });
        setIsLoading(false);
      } catch (error) {
        console.log("Error:", error);
        alert(error?.response?.data?.error || error);
        setIsLoading(false);
      }
    }
  };

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      source: allowUpload ? CameraSource.Prompt : CameraSource.Camera,
      resultType: CameraResultType.Uri,
    });
    const blob = await fetch(image.webPath).then((r) => r.blob());
    uploadFile(blob);
  };

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Menu />
      <Box ml={3}>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 800,
            lineHeight: "38px",
            color: "#000000",
            opacity: 1,
            letterSpacing: 0,
          }}
        >
          {state?.isNewOnboard ? (
            `Let's look at some wine.`
          ) : (
            <>
              {greeting},
              <br />
              <span
                style={{
                  fontSize: "36px",
                  fontWeight: 800,
                  color: "#000000",
                  opacity: 0.4,
                }}
              >
                {user?.name?.split(" ")[0] || localStorage.getItem("userName")}.
              </span>
            </>
          )}
        </Typography>

        <Typography
          sx={{
            mt: 5,
            fontSize: "25px",
            letterSpacing: 0,
            fontWeight: 500,
            color: "#000000",
            opacity: 1,
          }}
        >
          {state?.isNewOnboard
            ? `Simply tap the camera icon to start`
            : `Let's look at some wines.`}
        </Typography>
      </Box>

      <Box>
        <Box
          sx={{ textAlign: "center", mt: 14, cursor: "pointer" }}
          onClick={takePicture}
        >
          <CameraAltOutlinedIcon sx={{ fontSize: 105, color: "#017101" }} />
          {/* <input
            type="file"
            id="fileInput"
            accept="image/"
            capture={allowUpload ? undefined : "camera"}
            style={{ display: "none" }}
            name="file"
            onChange={handleFileChange}
          /> */}
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 500,
            }}
          >
            Tap to start
          </Typography>
        </Box>

        <BottomNavigationButton />
      </Box>
    </Box>
  );
}

export default Dashboard;
