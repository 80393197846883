import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMyContext } from "./context";
import { useEffect } from "react";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";

const client = generateClient();

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, setIsLoading, setIsAuthenticated, setUser } =
    useMyContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const userName = localStorage.getItem("userName");
      if (userName) {
        setIsAuthenticated(true);
      }

      const { userId } = await getCurrentUser();

      setIsLoading(true);
      const userData = await client.graphql({
        query: queries.getUser,
        variables: { id: userId },
      });
      if (!userData?.data?.getUser) {
        const newUserObj = await fetchUserAttributes();
        try {
          const userDetails = {
            id: newUserObj?.sub,
            email: newUserObj?.email,
            name: newUserObj?.name,
          };
          await client.graphql({
            query: mutations.createUser,
            variables: { input: userDetails },
          });
          navigate("/features", { state: { name: newUserObj?.name } });
        } catch (error) {}
      }
      setIsLoading(false);
      localStorage.setItem("userName", userData?.data?.getUser?.name);
      setIsAuthenticated(true);
      setUser({ userId, ...userData?.data?.getUser });
    } catch (error) {
      console.log("Not signed in", error);
      setIsLoading(false);
      setIsAuthenticated(false); // do not change this
    }
  };

  return isAuthenticated === null ? null : isAuthenticated ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
