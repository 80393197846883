/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name
      testProfileConfig
      attitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      testProfileConfig
      attitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name
      testProfileConfig
      attitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUerWineHistory = /* GraphQL */ `
  mutation CreateUerWineHistory(
    $input: CreateUerWineHistoryInput!
    $condition: ModelUerWineHistoryConditionInput
  ) {
    createUerWineHistory(input: $input, condition: $condition) {
      id
      userId
      wineImg
      response
      favorite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUerWineHistory = /* GraphQL */ `
  mutation UpdateUerWineHistory(
    $input: UpdateUerWineHistoryInput!
    $condition: ModelUerWineHistoryConditionInput
  ) {
    updateUerWineHistory(input: $input, condition: $condition) {
      id
      userId
      wineImg
      response
      favorite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUerWineHistory = /* GraphQL */ `
  mutation DeleteUerWineHistory(
    $input: DeleteUerWineHistoryInput!
    $condition: ModelUerWineHistoryConditionInput
  ) {
    deleteUerWineHistory(input: $input, condition: $condition) {
      id
      userId
      wineImg
      response
      favorite
      createdAt
      updatedAt
      __typename
    }
  }
`;
