import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { deleteUser } from "aws-amplify/auth";
import * as mutations from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useMyContext } from "../context";
import * as queries from "../graphql/queries";

const client = generateClient();

function DeleteAccount() {
  const { setIsLoading, user } = useMyContext();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUserAccount = async () => {
    try {
      setOpen(false);

      setIsLoading(true);

      const { data } = await client.graphql({
        query: queries.listUerWineHistories,
        variables: { filter: { userId: { eq: user?.userId } }, limit: 30000 },
      });

      await Promise.all(
        data.listUerWineHistories.items.map(async (item) => {
          return await client.graphql({
            query: mutations.deleteUerWineHistory,
            variables: {
              input: { id: item.id },
            },
          });
        })
      );

      await client.graphql({
        query: mutations.deleteUser,
        variables: { input: { id: user.userId } },
      });

      await deleteUser();
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="text"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          textTransform: "inherit",
        }}
        onClick={() => setOpen(true)}
      >
        Delete Account
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We're sorry to see you go! Are you sure you want to delete your
            account? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUserAccount} color="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteAccount;
