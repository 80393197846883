import { useState } from "react";
import { Box, Button, Slider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { useMyContext } from "../context";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const client = generateClient();

const task = [
  {
    id: 1,
    name: "1_lb",
    task: "Do you prefer wines that are more light or bold?",
    fontLeft: "Light",
    fontRight: "Bold",
  },
  {
    id: 2,
    name: "2_st",
    task: "Do you prefer wines that are more smooth or tannic?",
    fontLeft: "Smooth",
    fontRight: "Tannic",
  },
  {
    id: 3,
    name: "3_ds",
    task: "Do you prefer wines that are more dry or sweet?",
    fontLeft: "Dry",
    fontRight: "Sweet",
  },
  {
    id: 4,
    name: "4_sa",
    task: "Do you prefer wines that are soft or acidic?",
    fontLeft: "Soft",
    fontRight: "Acidic",
  },
];

const marks = [
  {
    value: 1,
    label: "",
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 3,
    label: "",
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 5,
    label: "",
  },
];

const initialValues = task.reduce((acc, curr) => {
  acc[curr.name] = 0;
  return acc;
}, {});

const Task = () => {
  const [taskValues, setTaskValues] = useState(initialValues);
  const { user, setIsLoading, setUser } = useMyContext();
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleChange = (index) => (newValue) => {
    const taskName = task[index].name;
    const newValueString = newValue.toString();

    setTaskValues((prevValues) => ({
      ...prevValues,
      [taskName]: newValueString,
    }));
  };

  const saveDetailsDB = async () => {
    try {
      setIsLoading(true);
      const todoDetails = {
        id: user.userId,
        testProfileConfig: JSON.stringify(taskValues),
      };
      const dd = await client.graphql({
        query: mutations.updateUser,
        variables: { input: todoDetails },
      });
      setUser({ ...user, ...dd.data.updateUser });
      setIsLoading(false);
      navigate("/dashboard", { state: { isNewOnboard: true } });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleNext = () => {
    if (task[currentIndex].id === 4) {
      saveDetailsDB();
    }

    if (currentIndex < task.length - 1) {
      if (taskValues[currentIndex] === 0) {
        setCurrentIndex(currentIndex);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box>
        <Typography
          sx={{
            mt: 2,
            fontSize: "24px",
            fontWeight: 500,
            color: "#000000",
            opacity: 0.9,
          }}
        >
          {task[currentIndex].task}
        </Typography>

        <Box
          sx={{
            mt: 22,
            mb: 28,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              marginRight: "15px",
              fontSize: "20px",
              fontWeight: 500,
              color: "#000000",
              opacity: 0.4,
            }}
          >
            {task[currentIndex].fontLeft}
          </Typography>
          <Box sx={{ flex: 1, margin: "0 10px" }}>
            <Slider
              aria-labelledby="discrete-slider"
              value={taskValues[task[currentIndex].name]}
              onChange={(e, newValue) => handleChange(currentIndex)(newValue)}
              marks={marks}
              step={null}
              min={1}
              max={5}
              sx={{
                "& .MuiSlider-thumb": {
                  borderRadius: "8px",
                  width: 3,
                  height: 25,
                },
              }}
            />
          </Box>

          <Typography
            sx={{
              marginLeft: "15px",
              fontSize: "20px",
              fontWeight: 500,
              color: "#000000",
              opacity: 0.4,
            }}
          >
            {task[currentIndex].fontRight}
          </Typography>
        </Box>
      </Box>

      <Box mt={30}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent:
              task[currentIndex].id !== 1 ? "space-between" : "end",
          }}
        >
          {task[currentIndex].id !== 1 && (
            <Button
              variant="text"
              sx={{
                fontWeight: 400,
                color: " #000000",
                opacity: 0.9,
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          <Button
            endIcon={<ChevronRightIcon style={{ fontSize: "20px" }} />}
            sx={{
              borderRadius: "9px",
              backgroundColor: "rgba(60, 127, 38, 1)",
              "&:hover": {
                backgroundColor: "rgba(60, 127, 38, 1)",
              },
            }}
            onClick={handleNext}
            variant="contained"
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Task;
