import Spinner from "react-spinner-material";
import { useMyContext } from "../context";
import logo from "../assets/Image/logo.svg";

export const Loader = () => (
  <div className="loadingSpinner">
    <div style={{ width: "125px", height: "125px", position: "relative" }}>
      <img
        src={logo}
        alt=""
        style={{
          width: "124px",
          height: "124px",
          position: "absolute",
        }}
      />
      <Spinner
        size={100}
        color={"#017101"}
        style={{ width: "inherit", height: "inherit" }}
      />
    </div>
  </div>
);

function SpinnerLoading() {
  const { isLoading } = useMyContext();

  return <>{isLoading && <Loader />}</>;
}

export default SpinnerLoading;
