import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useLocation } from "react-router-dom";

const selectedStyle = {
  color: "#017101",
  opacity: 1,
  "&.Mui-selected": {
    color: "#017101",
    opacity: 1,
  },
};
const iconStyle = { color: "#017101", opacity: 1, fontSize: "40px" };

function BottomNavigationButton() {
  const navigate = useNavigate();
  const [value, setValue] = useState("2");
  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case "/favoriteList":
        setValue("1");
        break;
      case "/dashboard":
        setValue("2");
        break;
      case "/userProfile":
        setValue("3");
        break;
      default:
        break;
    }
  }, [pathname]);

  return (
    <Box>
      <Box
        sx={{ position: "absolute", bottom: 10, width: "calc(100% - 40px)" }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            switch (newValue) {
              case "1":
                navigate("/favoriteList");
                break;
              case "2":
                navigate("/dashboard");
                break;
              case "3":
                navigate("/userProfile");
                break;
              default:
                break;
            }
          }}
        >
          <BottomNavigationAction
            value="1"
            sx={selectedStyle}
            icon={
              value === "1" ? (
                <FavoriteOutlinedIcon style={iconStyle} />
              ) : (
                <FavoriteBorderOutlinedIcon style={iconStyle} />
              )
            }
          />

          <BottomNavigationAction
            value="2"
            sx={selectedStyle}
            icon={
              value === "2" ? (
                <CameraAltIcon style={iconStyle} />
              ) : (
                <CameraAltOutlinedIcon style={iconStyle} />
              )
            }
          />
          <BottomNavigationAction
            value="3"
            sx={selectedStyle}
            icon={
              value === "3" ? (
                <PersonRoundedIcon style={iconStyle} />
              ) : (
                <PersonOutlinedIcon style={iconStyle} />
              )
            }
          />
        </BottomNavigation>
      </Box>
    </Box>
  );
}

export default BottomNavigationButton;
