import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField, Link } from "@mui/material";
import { Hub } from "aws-amplify/utils";
import { Icon } from "@iconify/react";
import {
  getCurrentUser,
  signInWithRedirect,
  signOut,
  signIn,
} from "aws-amplify/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useMyContext } from "../context";
import logo from "../assets/Image/logo.svg";
import { Loader } from "../Components/SpinnerLoading";

function Login() {
  const navigate = useNavigate();
  const { setIsLoading } = useMyContext();
  const [isChecking, setIsChecking] = useState(true);
  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          navigate("/dashboard");
          break;
        // case "signInWithRedirect_failure":
        //   alert("An error has occurred during the OAuth flow.");
        //   break;
        case "customOAuthState":
          console.log(payload.data); // this is the customState provided on signInWithRedirect function
          break;
        default:
          break;
      }
    });

    const userName = localStorage.getItem("userName");
    if (userName) {
      navigate("/dashboard");

      getCurrentUser().catch(() => {
        localStorage.removeItem("userName");
        setIsLoading(false);
        navigate("/");
      });
    } else {
      signOut();
    }
    setIsChecking(false);

    return unsubscribe;
  }, []);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);

      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const { nextStep } = await signIn({
        username: data.get("email"),
        password: data.get("password"),
      });
      setIsLoading(false);

      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        alert("User is not confirmed.");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert(error?.response?.data?.error || error);
    }
  };

  if (isChecking) {
    return <Loader />;
  }

  const showEmailAdd = () => {
    setShowEmail(true);
  };

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <img
          src={logo}
          alt=""
          style={{
            width: "130px",
            height: "130px",
            display: "block",
            margin: "auto",
          }}
        />
        <Typography
          sx={{
            mt: 5,
            fontSize: "26px",
            fontWeight: 800,
            color: "#000000",
            opacity: 1,
            textAlign: "center",
          }}
        >
          Choose the perfect
          <br />
          wine, every time.
        </Typography>
      </Box>
      <Box mt={5} sx={{ textAlign: "center" }}>
        {showEmail ? (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="success"
            >
              Sign In
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link
                onClick={() => setShowEmail(false)}
                variant="body2"
                color="#017101"
              >
                {"Go to Social login"}
              </Link>
              <Link
                to="/signup"
                variant="body2"
                component={RouterLink}
                color="#017101"
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                flexDirection: "column",
              }}
            >
              <Link
                to="/forgotPassword"
                variant="body2"
                component={RouterLink}
                color="#017101"
                sx={{ mt: 2 }}
              >
                Forgot password
              </Link>
            </Box>
          </Box>
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                border: "2px solid rgba(226, 232, 240, 1)",
                borderRadius: "50px",
                px: 3,
                py: 2,
                mt: 2,
                fontSize: "16px",
                fontWeight: 500,
                color: "#1e293b",
                opacity: 1,
                textTransform: "capitalize",
                "&:hover": {
                  border: "2px solid rgba(30, 41, 59, 1)",
                },
              }}
              startIcon={
                <Icon icon="devicon:apple" style={{ fontSize: "20px" }} />
              }
              onClick={() => signInWithRedirect({ provider: "Apple" })}
            >
              Sign in with Apple
            </Button>

            <Button
              variant="outlined"
              sx={{
                mt: 2,
                mb: 5,
                width: "100%",
                border: "2px solid rgba(226, 232, 240, 1)",
                borderRadius: "50px",
                px: 3,
                py: 2,
                fontSize: "16px",
                fontWeight: 500,
                color: "#1e293b",
                opacity: 1,
                textTransform: "capitalize",
                "&:hover": {
                  border: "2px solid rgba(30, 41, 59, 1)",
                },
              }}
              startIcon={
                <Icon
                  icon="flat-color-icons:google"
                  style={{ fontSize: "20px" }}
                />
              }
              onClick={() => signInWithRedirect({ provider: "Google" })}
            >
              Sign in with Google
            </Button>
            <Box
              mt={0}
              sx={{
                textAlign: "center",
                width: "100%",
                fontSize: "16px",
                fontWeight: 500,
                color: "#1e293b",
                opacity: 1,
                textTransform: "capitalize",
              }}
            >
              Login With Email?{" "}
              <Box
                component="span"
                onClick={showEmailAdd}
                sx={{
                  color: "#017101",
                  cursor: "pointer",
                  "&:hover": { color: "#083a08" },
                }}
              >
                Click Here
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Login;
