import React, { useEffect, useState } from "react";
import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useMyContext } from "../context";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import logo from "../assets/Image/logo.svg";

function ForgotPassword() {
  const { setIsLoading } = useMyContext();
  const navigate = useNavigate();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [username, setUsername] = useState();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const data = new FormData(event.currentTarget);
      const username = data.get("email");
      setUsername(username);
      const { nextStep } = await resetPassword({ username });
      setIsLoading(false);

      switch (nextStep.resetPasswordStep) {
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          alert(
            `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
          );
          setIsCodeSent(true);
          break;
        case "DONE":
          alert("Successfully reset password.");
          navigate("/");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert(error?.response?.data?.error || error);
    }
  };

  const handleConfirmResetPassword = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const data = new FormData(event.currentTarget);
      await confirmResetPassword({
        username,
        confirmationCode: data.get("confirmationCode"),
        newPassword: data.get("newPassword"),
      });
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert(error?.response?.data?.error || error);
    }
  };

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          mt: 8,
        }}
      >
        <img
          src={logo}
          alt=""
          style={{
            width: "130px",
            height: "130px",
            display: "block",
            margin: "auto",
          }}
        />
        <Typography
          sx={{
            mt: 10,
            fontSize: "26px",
            fontWeight: 800,
            color: "#000000",
            opacity: 1,
            textAlign: "center",
          }}
        >
          Forgot password
        </Typography>
      </Box>
      <Box mt={10} sx={{ textAlign: "center" }}>
        <Box
          component="form"
          onSubmit={isCodeSent ? handleConfirmResetPassword : handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          {isCodeSent ? (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmationCode"
                label="Enter Confirmation Code"
                name="confirmationCode"
                autoComplete="off"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Enter New Password"
                name="newPassword"
                type="password"
                autoComplete="off"
              />
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="success"
          >
            {isCodeSent ? `Submit` : ` Send Reset Code`}
          </Button>

          {!isCodeSent && (
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                flexDirection: "column",
              }}
            >
              <Link
                to="/"
                variant="body2"
                component={RouterLink}
                color="#017101"
                sx={{ mt: 2 }}
              >
                Login
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
