import { Box, Button, Slider, Typography } from "@mui/material";
import BottomNavigationButton from "../Components/BottomNavigationButton";
import Menu from "../Components/Menu";
import { useEffect, useState } from "react";
import { useMyContext } from "../context";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

const client = generateClient();

const testProfile = [
  { name: "1_lb", fontLeft: "Light", fontRight: "Bold" },
  {
    name: "2_st",
    fontLeft: "Smooth",
    fontRight: "Tannic",
  },
  {
    name: "3_ds",
    fontLeft: "Dry",
    fontRight: "Sweet",
  },
  {
    name: "4_sa",
    fontLeft: "Soft",
    fontRight: "Acidic",
  },
];

const marks = [
  {
    value: 1,
    label: "",
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 3,
    label: "",
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 5,
    label: "",
  },
];

function MyTestProfile() {
  const [sliderValues, setSliderValues] = useState();
  const { user, setIsLoading, setUser } = useMyContext();

  useEffect(() => {
    if (user?.userId) {
      setIsLoading(true);
      client
        .graphql({
          query: queries.getUser,
          variables: { id: user.userId },
        })
        .then(({ data }) => {
          const testProfile = JSON.parse(data.getUser.testProfileConfig);
          setSliderValues(testProfile);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [user]);

  const handleChange = (e, newValue, key) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };

  const saveDetailsDB = async () => {
    try {
      setIsLoading(true);
      const todoDetails = {
        id: user.userId,
        testProfileConfig: JSON.stringify(sliderValues),
      };
      const dd = await client.graphql({
        query: mutations.updateUser,
        variables: { input: todoDetails },
      });
      setUser({ ...user, ...dd.data.updateUser });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box>
          <Menu />

          <Box mt={10}>
            {sliderValues &&
              testProfile.map((item, index) => {
                return (
                  <Box
                    sx={{
                      mt: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        marginRight: "15px",
                        color: "#000000",
                        opacity: 0.4,
                      }}
                    >
                      {item.fontLeft}
                    </Typography>
                    <Box sx={{ flex: 1, margin: "0 10px" }}>
                      <Slider
                        key={item.name}
                        value={sliderValues[item.name]}
                        onChange={(event, newValue) =>
                          handleChange(event, newValue, item.name)
                        }
                        marks={marks}
                        min={1}
                        max={5}
                        step={null}
                        sx={{
                          "& .MuiSlider-thumb": {
                            borderRadius: "8px",
                            width: 4,
                            height: 25,
                          },
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        marginLeft: "15px",
                        color: "#000000",
                        opacity: 0.4,
                      }}
                    >
                      {item.fontRight}
                    </Typography>
                  </Box>
                );
              })}
            <Box textAlign={"center"} mt={5}>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 400,
                  backgroundColor: "rgba(60, 127, 38, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(60, 127, 38, 1)",
                  },
                }}
                onClick={saveDetailsDB}
              >
                Save
              </Button>
            </Box>
          </Box>

          <Box>
            <BottomNavigationButton />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyTestProfile;
