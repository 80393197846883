import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import {
  SwipeableList,
  SwipeableListItem,
  // LeadingActions,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";

import "react-swipeable-list/dist/styles.css";

import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
// import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Menu from "../Components/Menu";
import BottomNavigationButton from "../Components/BottomNavigationButton";
import * as mutations from "../graphql/mutations";
import { useMyContext } from "../context";
import { useNavigate } from "react-router-dom";

const client = generateClient();

// const StyledTab = styled(Tab)({
//   "&.Mui-selected": {
//     color: "#017101",
//   },
// });

const styleAction = {
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  color: "#eee",
};

function FavoriteList() {
  const { setIsLoading, user, historyData, fetchHistory } = useMyContext();
  const navigate = useNavigate();
  // const [value, setValue] = useState("2");

  useEffect(() => {
    if (user?.userId && historyData.length === 0) fetchHistory();
  }, [user]);

  // const leadingActions = ({ id }) => (
  //   <LeadingActions>
  //     <SwipeAction onClick={() => console.info("swipe action triggered")}>
  //       <Box
  //         sx={{
  //           ...styleAction,
  //           backgroundColor: "#4a7b2b",
  //         }}
  //       >
  //         Favorite
  //       </Box>
  //     </SwipeAction>
  //   </LeadingActions>
  // );

  const deleteItem = async (id) => {
    try {
      setIsLoading(true);
      await client.graphql({
        query: mutations.deleteUerWineHistory,
        variables: { input: { id: id } },
      });
      setIsLoading(false);
      fetchHistory();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const trailingActions = ({ id }) => (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={() => deleteItem(id)}>
        <Box sx={{ ...styleAction, backgroundColor: "#bc281e" }}>
          <DeleteForeverIcon />
        </Box>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box>
        <Menu />
        <Typography sx={{ color: "#017101", fontSize: "20px" }}>
          History
        </Typography>
        <Box mt={5} mb={5}>
          {/* <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                variant="fullWidth"
                TabIndicatorProps={{
                  style: { backgroundColor: "#017101" },
                }}
              >
                <StyledTab label="Favourites" value="1" />
                <StyledTab label="History" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "10px" }}>
              Item One
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "10px" }}>
          Item two
            </TabPanel>
          </TabContext> */}
          {historyData.length === 0 && (
            <Typography sx={{ textAlign: "center" }}>No History</Typography>
          )}
          <Box sx={{ overflow: "auto", height: "65vh" }}>
            <SwipeableList>
              {historyData.map((item) => {
                let arrItem = item.response && JSON.parse(item.response);
                arrItem = Array.isArray(arrItem) ? arrItem : arrItem?.data;
                return (
                  <SwipeableListItem
                    key={item.id}
                    // leadingActions={leadingActions({ id: item.id })}
                    trailingActions={trailingActions({ id: item.id })}
                  >
                    <Card
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        padding: 2,
                        alignItems: "center",
                        width: "100%",
                      }}
                      onClick={() =>
                        navigate("/resultScreen", {
                          state: {
                            result: arrItem,
                            wineImg: item.wineImg,
                          },
                        })
                      }
                    >
                      <img
                        src={item.wineImg}
                        style={{ height: "100px", width: "100px" }}
                        alt={item?.id}
                      />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          ml: 2,
                        }}
                      >
                        {arrItem?.map((item) => item.Name)?.join(" , ")}
                      </Typography>
                    </Card>
                  </SwipeableListItem>
                );
              })}
            </SwipeableList>
          </Box>
        </Box>

        <BottomNavigationButton />
      </Box>
    </Box>
  );
}

export default FavoriteList;
