import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./pages/Login";
import Features from "./pages/Features";
import Task from "./Components/Task";
import FavoriteList from "./pages/FavoriteList";
import UserProfile from "./pages/UserProfile";
import MyTestProfile from "./pages/MyTestProfile";
import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import ResultScreen from "./pages/ResultScreen";
import ForgotPassword from "./pages/ForgotPassword";
import { CtxProvider } from "./context";
import ProtectedRoute from "./ProtectedRoute";
import AppUrlListener from "./Components/AppUrlListener";

function UserRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />

      <Route
        path="/features"
        element={
          <ProtectedRoute>
            <Features />
          </ProtectedRoute>
        }
      />
      <Route
        path="/task"
        element={
          <ProtectedRoute>
            <Task />
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/favoriteList"
        element={
          <ProtectedRoute>
            <FavoriteList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userProfile"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/myTestProfile"
        element={
          <ProtectedRoute>
            <MyTestProfile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/resultScreen"
        element={
          <ProtectedRoute>
            <ResultScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <div>
            <h1>404</h1>
          </div>
        }
      />
    </Routes>
  );
}

const THEME = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Open Sans",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "500px", backgroundColor: "rgba(255, 255, 255, 1)" }}>
          <CtxProvider>
            <Router>
              <AppUrlListener />
              <UserRoutes />
            </Router>
          </CtxProvider>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
