import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../Components/Menu";
import BottomNavigationButton from "../Components/BottomNavigationButton";
import { useMyContext } from "../context";

const RoundView = ({ value, label, color }) => (
  <Box
    sx={{
      color: color,
      borderRadius: "100px",
      border: 1,
      width: "91px",
      height: "91px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <Typography sx={{ fontWeight: 800, fontSize: "24px" }} component="span">
      {value}
    </Typography>
    <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>{label}</Typography>
  </Box>
);

const TextItem = ({ label, value }) => (
  <>
    <Typography component="span" fontWeight={600} mr={1}>
      {label}:
    </Typography>
    {value}
    <br />
  </>
);

const getColorText = (score) => {
  score = Number(score);
  if (score >= 71 && score <= 100) {
    return {
      color: "#017101",
      text: "You'll love this wine",
    };
  } else if (score >= 50 && score <= 70) {
    return {
      color: "#34A87E",
      text: "You'll like this wine",
    };
  } else {
    return {
      color: "#FBBC05",
      text: "You'll probably dislike this wine",
    };
  }
};

const MultiWine = ({ result }) => {
  const { user } = useMyContext();

  return (
    <Box>
      <Typography mb={2}>
        Hey {user?.name?.split(" ")[0]}, here are some wines you might like:
      </Typography>
      {result.map((item) => {
        const dd = getColorText(item.Percentage_match);
        return (
          <Box>
            <Typography fontWeight={800}>
              {item?.Name} {` ${item.Year}`}
            </Typography>
            <ul>
              <li
                style={{ color: dd.color }}
              >{`${item.Percentage_match}% match`}</li>
              <li style={{ color: dd.color }}>{`${item.Rating} rating`}</li>
              <li style={{ color: dd.color }}>{dd.text}</li>
            </ul>
            <Typography mt={1} mb={2}>
              {item?.Notes}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const SingleWine = ({ item }) => {
  const dd = getColorText(item?.Percentage_match);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <RoundView value={`${item?.Rating}`} label="rating" color={dd.color} />
        <RoundView
          value={`${item?.Percentage_match}%`}
          label="match"
          color={dd.color}
        />
      </Box>

      <Typography
        sx={{
          color: dd.color,
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.4rem",
        }}
        mt={3}
      >
        {dd.text}
      </Typography>
      <Typography mt={3}>{item?.Notes}</Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "18px" }} mt={3} mb={1}>
        Detail
      </Typography>
      <Box>
        <TextItem label="Name" value={item?.Name} />
        <TextItem label="Year" value={item?.Year} />
        <TextItem label="Price range" value={item?.Price_range} />
        <TextItem label="Grapes" value={item?.Grapes} />
        <TextItem label="Flavours" value={item?.Flavours} />
        <TextItem label="Food pairing" value={item?.Food_pairing} />
        <TextItem label="Region" value={item?.Region} />
        <TextItem label="About the winery" value={item?.producer} />
      </Box>
    </Box>
  );
};

function ResultScreen() {
  const {
    state: { result = "", wineImg = null },
  } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!result) {
      navigate("/dashboard");
    }
  }, [result]);

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box>
        <Menu />

        <Box sx={{ overflow: "auto", height: "75vh" }} mt={5}>
          {result.length && result.length <= 1 ? (
            <SingleWine item={result[0]} />
          ) : (
            <MultiWine result={result} />
          )}
          {wineImg && (
            <Box mt={4} mb={2} textAlign="center">
              <img src={wineImg} style={{ width: "100%" }} alt="no url" />
            </Box>
          )}
        </Box>
        <BottomNavigationButton />
      </Box>
    </Box>
  );
}

export default ResultScreen;
