import { useEffect } from "react";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const AppUrlListener = () => {
  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      console.log("App opened with URL: " + event.url);
      if (event.url.startsWith("heysomm")) {
        const url = event.url.replace(
          "heysomm",
          Capacitor.getPlatform() === "ios" ? "capacitor" : "https"
        );
        window.location.replace(url);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
