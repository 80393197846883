import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import logo from "../assets/Image/logo.svg";
import Container from "@mui/material/Container";
import { signUp, signIn } from "aws-amplify/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useMyContext } from "../context";

function Signup() {
  const navigate = useNavigate();
  const { setIsLoading } = useMyContext();

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);

      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get("email");
      const name = data.get("name");

      await signUp({
        username: email,
        password: data.get("password"),
        options: {
          userAttributes: {
            email: email,
            given_name: name,
            name: name,
          },
          autoSignIn: true,
        },
      });
      await signIn({
        username: data.get("email"),
        password: data.get("password"),
      });
      localStorage.setItem("userName", data.get("email"));
      setIsLoading(false);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.error || error);
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt=""
          style={{
            width: "130px",
            height: "130px",
            display: "block",
            margin: "auto",
          }}
        />
        <Box sx={{ mt: 5 }}>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="success"
            >
              Sign Up
            </Button>
          </Box>
          <Box
            mt={3}
            sx={{
              textAlign: "center",
              width: "100%",
              fontSize: "16px",
              fontWeight: 500,
              color: "#1e293b",
              opacity: 1,
              textTransform: "capitalize",
            }}
          >
            Already have an account?
            <Link to="/" component={RouterLink} color="#017101" sx={{ ml: 1 }}>
              {"Sign In here"}
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Signup;
