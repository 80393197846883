import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import menu from "../assets/Image/np_menu.svg";
import bottles from "../assets/Image/wine_bottles.svg";
import label from "../assets/Image/np_label.svg";
import sliders from "../assets/Image/np_sliders.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const features = [
  {
    src: menu,
    type: "Take a photo of a wine list.",
  },
  {
    src: bottles,
    type: "Take a photo of several wine bottles on a shelf.",
  },
  {
    src: label,
    type: "Take a photo of a wine label.",
  },
];

function Features() {
  const navigate = useNavigate();
  const [nextStep, setNextStep] = useState(false);
  const { state } = useLocation();

  const handleClick = () => {
    navigate("/task");
  };

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      {nextStep ? (
        <>
          <Box>
            <Typography
              sx={{
                mt: 2,
                fontSize: "36px",
                lineHeight: 1,
                letterSpacing: 1.3,
                fontWeight: 800,
                color: "#000000",
                opacity: 1,
              }}
            >
              Welcome,
              <br />
              <span
                style={{
                  fontSize: "36px",
                  fontWeight: 800,
                  color: "#000000",
                  opacity: 0.5,
                }}
              >
                {state?.name}.
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                fontSize: "20px",
                fontWeight: 500,
                color: "#000000",
                opacity: 0.4,
              }}
            >
              Lets start by telling me about the wines you like. This will take
              less than 1 min.
            </Typography>
          </Box>
          <Box>
            <Box mt={8} sx={{ textAlign: "center" }}>
              <img
                src={sliders}
                alt="sliders"
                style={{ width: "150px", height: "150px" }}
              />
            </Box>
            <Box mt={20} sx={{ textAlign: "end" }}>
              <Button
                endIcon={<ChevronRightIcon style={{ fontSize: "20px" }} />}
                sx={{
                  borderRadius: "9px",
                  backgroundColor: "rgba(60, 127, 38, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(60, 127, 38, 1)",
                  },
                }}
                onClick={handleClick}
                variant="contained"
              >
                Next
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: 800,
                color: "#000000",
                opacity: 1,
              }}
            >
              Features
            </Typography>
            <Typography
              sx={{
                mt: 1,
                fontSize: "20px",
                fontWeight: 500,
                color: "#000000",
                opacity: 0.4,
              }}
            >
              Personalised wine suggestions are only a tap away.
            </Typography>
          </Box>
          <Box>
            <Box mt={8}>
              {features.map((data, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      mt: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={data.src}
                      alt="src"
                      style={{ width: "70px", height: "70px" }}
                    />
                    <Typography
                      sx={{
                        width: "70%",
                        fontSize: "20px",
                        fontWeight: 500,
                        color: "#000000",
                        opacity: 0.4,
                        letterSpacing: "1.5",
                      }}
                    >
                      {data.type}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box mt={13} sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  width: "199px",
                  borderRadius: "2px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  color: "#ffffff",
                  opacity: 1,
                  backgroundColor: "rgba(60, 127, 38, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(60, 127, 38, 1)",
                    color: "rgba(0, 0, 0, 0.47)",
                  },
                }}
                onClick={() => setNextStep(true)}
                variant="contained"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Features;
